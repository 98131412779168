body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
    font-family: "Roboto", sans-serif;
    text-align: center;
    color: #234361;
}

.align-center {
    text-align: center;
}

.wrapper {
    display: inline-block;
    max-width: 780px;
    text-align: left;
    text-align: initial;
    font-size: 14px;
}

.processing {
    font-size: 12px;
    letter-spacing: 0px;
}

.processing .paragraph {
    margin: 10px 0;
}
.footer {
    font-size: 11px;
}
h1 {
    color: #234361;
    font-size: 28px;
    margin-bottom: 30px;
}
p {
    /* margin-bottom: 5px; */
}
.paragraph {
    display: flex;
    align-items: center;
    margin: 12px 0;
}
.paragraph.center {
    text-align: center;
    justify-content: center;
}

.paragraph.spaced {
    justify-content: space-between;
}
.paragraph.indent {
    /* text-indent: 30px; */
    padding-left: 30px;
}
.paragraph > input[type="text"],
.paragraph > textarea {
    flex-grow: 1;
}
.processing input {
    /* margin: 0; */
    padding: 4px 0;
}
.paragraph > input[type="checkbox"] {
    min-width: 24px;
    height: 24px;
}
.sep {
    margin-bottom: 30px;
}
sup {
    align-items: center;
}
input,
textarea,
select {
    color: #234361;
    border-color: #234361;
    margin: 0 5px;
    outline: none;
    border: 1px solid;
    border-color: #bdbdbd;
    background-color: #f5f5f5;
    border-radius: 3px;
    padding: 4px 5px;
    font-weight: bold;
}

textarea {
    height: 50px;
}

h5 {
    color: #66788a;
}

a,
a:visited {
    color: #1070ca;
}

.processing input,
.processing textarea {
    border: none;
    background-color: none;
}

input:focus {
    border-color: #1070ca;
}

input.full,
textarea.full {
    width: 100%;
}
input.small {
    max-width: 40px;
}
input.medium {
    max-width: 140px;
}

.footer .paragraph {
    /* align-items: initial; */
}

sup {
    font-weight: bold;
    margin-top: -8px;
}

.message {
    border: 1px solid;
    border-radius: 4px;
    padding: 10px;
}
.message p {
    margin: 0 0 5px 0;
}

.message.danger {
    background-color: #fae2e2;
    border-color: #ec4c47;
    color: #bf0e08;
}

.message.primary {
    background-color: #ddebf7;
    border-color: #1070ca;
    color: #2267a9;
}

.bottom {
    margin-top: 30px;
}

.button {
    border-radius: 3px;
    border: 1px solid;
    /* border: none; */
    padding: 8px 12px;
    /* font-weight: bold; */
    font-size: 16px;
    font-weight: bold;
    margin: 0 5px;
}

.button.success {
    background-color: #23c277;
    color: #ffffff;
    border-color: #47b881;
    text-shadow: 1px 1px 1px #47b881;
}

.button.primary {
    background-color: #1070ca;
    color: #ffffff;
    border-color: #2267a9;
    text-shadow: 1px 1px 1px #2267a9;
}

